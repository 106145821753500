import React, { useCallback } from 'react';
import { Alert, Button, Col, DatePicker, Form, Input, message, Modal, Row, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../hooks/useToggle';
import dayjs from 'dayjs';
import { useRescheduleLessonMutation } from '../../store/apis/schedule';
import { useGetServiceConfigQuery } from '../../store/apis/serviceConfig';
import { getHoursByMinutes } from '../../utils';

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const RescheduleLesson = ({
  scheduleId,
  lessonTime,
}) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();

  const [rescheduleLesson, { isLoading, isFetching }] = useRescheduleLessonMutation();
  const {
    data: sysConfig,
  } = useGetServiceConfigQuery();

  const [form] = Form.useForm();

  const closeAndReset = useCallback(() => {
    form.resetFields();
    close();
  }, [close, form]);

  const handleSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      const {
        id,
        ...body
      } = values;

      const preparedBody = {
        ...body,
        toLessonTime: body.toLessonTime.format(),
      };

      await rescheduleLesson({ id, body: preparedBody }).unwrap();
      form.resetFields();
      close();
      message.success(t('Мы запросили у ученика перенос урока Ожидайте его ответ'))
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [close, form, rescheduleLesson, t]);

  const [newScheduleHoursBefore, newScheduleMinutesBefore] = getHoursByMinutes(sysConfig?.newScheduleMinutesBeforeStart);

  return (
    <>
      <Tooltip title={t('Перенести урок')}>
        <Button type="link" onClick={open}>
          ↪️
        </Button>
      </Tooltip>
      <Modal
        open={isOpen}
        title={t('Напишите комментарий к переносу урока')}
        okText={t('Перенести урок')}
        cancelText={t('Закрыть')}
        onCancel={closeAndReset}
        onOk={handleSubmit}
        confirmLoading={isLoading || isFetching}
      >
        <Alert
          type="warning"
          message={t(
            'Внимание! Время нового урока не может быть раньше чем {{timeText}} от текущего времени',
            {
              timeText: `${t('часов', { count: newScheduleHoursBefore })} ${t('минут', { count: newScheduleMinutesBefore })}`
            },
          )}
        />
        <Form
          style={{ marginTop: 20 }}
          form={form}
          layout="vertical"
          name="reschedule_lesson"
          initialValues={{
            id: scheduleId,
            currentLessonTime: dayjs(lessonTime),
            fromLessonTime: lessonTime,
          }}
        >
          <Form.Item hidden name="id" />
          <Form.Item hidden name="fromLessonTime" />
          <Row>
            <Col xs={12}>
              <Form.Item
                name="currentLessonTime"
                label={t('Текущее время урока')}
                rules={[
                  { required: true },
                ]}
              >
                <DatePicker showTime disabled format="DD.MM.YYYY HH:mm" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="toLessonTime"
                label={t('Новое время урока')}
                rules={[
                  { required: true },
                ]}
              >
                <DatePicker
                  showTime={{
                    minuteStep: 60,
                    defaultValue: dayjs().startOf('hour'),
                  }}
                  placeholder={t('Выберите дату и время')}
                  format="DD.MM.YYYY HH:mm"
                  disabledDate={(current) => {
                    const allowFromDate = dayjs().add(sysConfig?.newScheduleMinutesBeforeStart ?? 0, 'minutes');

                    if (!current || current.isBefore(allowFromDate, 'day')) return true;
                    if (current.isSame(allowFromDate, 'day')) return false;
                    return !current.isAfter(allowFromDate, 'day');
                    // date.isBefore(dayjs().add(sysConfig?.newScheduleMinutesBeforeStart ?? 0, 'minutes'))
                  }}
                  disabledTime={(current) => {
                    const allowFromDate = dayjs().add(sysConfig?.newScheduleMinutesBeforeStart ?? 0, 'minutes');

                    if (!current) return true;
                    if (current.isSame(allowFromDate, 'day')) {
                      return {
                        disabledHours: () => range(0 , allowFromDate.hour() + 1),
                        disabledMinutes: () => range(1, 60),
                      };
                    }

                    return {
                      disabledMinutes: () => range(1, 60),
                    };
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="comment"
            label={t('Комментарий')}
            rules={[
              { required: true },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 10 }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RescheduleLesson;
